















































import Vue from "vue";
import SignupButton from "@/components/SignupButton.vue";
import PreFooter from "@/components/PreFooter.vue";
import TwitterTestimonials from "@/components/TwitterTestimonials.vue";

export default Vue.extend({
  components: {
    PreFooter,
    TwitterTestimonials,
    SignupButton,
  },
  data() {
    return {
      browser: "Chrome",
    };
  },
  metaInfo(): { title: string } {
    return {title: `Import from ${this.browser} to Bkmark`};
  },
  methods: {
    getImportImageUrl(): string {
      const images = require.context("../../assets/images", false, /\.png$/);
      return images(`./import-${this.browser.toLowerCase()}.png`);
    },
  },
  async mounted() {
    this.browser = this.$browserDetect.isFirefox ? "Firefox" : this.browser;
    this.browser = this.$browserDetect.isChrome ? "Chrome" : this.browser;
    this.browser = this.$browserDetect.isEdge ? "Edge" : this.browser;
  },
});
